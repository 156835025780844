import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [LayoutComponent, HeaderComponent],
  exports: [ HeaderComponent ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class LayoutsModule { }
