<div class="container">

  <div class="card mt-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Categories</h4>
        </div>
      </div>

      <div class="row text-right mb-4">
        <div class="col">
          <button class="btn btn-primary btn-sm" type="button" (click)="openCategoryModal()">Add Category</button>
        </div>
      </div>

      <div>
        <table class="table">
          <thead>
          <tr>
            <th style="width: 10%">Sr #</th>
            <th style="width: 60%">Name</th>
            <th style="width: 18%">Action</th>
            <th style="width: 12%">Order</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of categories; let i = index">
            <td>{{i + 1}}</td>
            <td>{{data?.name}}</td>
            <td><span class="mr-3 pointer" (click)="editCat(data)"><i class="fas fa-edit"></i></span>
              <span class="pointer" (click)="deleteCat(data.id)"><i class="fas fa-trash-alt"></i></span></td>
            <td><input type="text" class="form-control" id="ordersSorting{{i}}" [(ngModel)]="data.priority" placeholder="Order" /></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row text-right">
        <div class="col-12" *ngIf="categories.length > 0">
          <button type="button" class="btn btn-primary" (click)="updateOrder()">Update Order</button>
        </div>
      </div>

    </div>
  </div>

</div>


<!-- modal start-->
<div id="catModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static" style="display: none;">
  <div class="modal-dialog modal-xl">
    <form [formGroup]="catForm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" *ngIf="addCat === true">Add Category</h4>
          <h4 class="modal-title" *ngIf="addCat === false">Update Category</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeCategoryModal()">×</button>
        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label class="control-label">Name</label>
                <input type="text" name="name" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': isSubmitCat && f.name.errors }" class="form-control">
              </div>
            </div>
            <div class="col-md-8 col-sm-12 col-12">
              <div class="form-group">
                <label class="control-label">Description</label>
                <input type="text" name="name" placeholder="Description" formControlName="description" [ngClass]="{ 'is-invalid': isSubmitCat && f.description.errors }" class="form-control">
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <div class="form-group">
                <div class="mb-4">
                  <label>Cat Image <span class="text-danger">*</span> (Image Ratio: 1 / 1)</label>
                  <button  type="button" (click)="selectImageHome()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-4">Upload Image</button>
                </div>
                <img *ngIf="showCropperHome === false && imgShow === 'shown'" [src]="baseUrl+image" class="m-t-5" style="width: 50%;">
                <div style="width: 400px;">
                  <image-cropper
                    [imageChangedEvent]="imageChangedEventHome"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1"
                    [imageQuality] = "75"
                    format="png"
                    (imageCropped)="imageCroppedHome($event)" *ngIf="showCropperHome === true"></image-cropper>
                </div>

              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <div class="mb-4">
                  <label>Cat Active Image <span class="text-danger">*</span> (Image Ratio: 1 / 1)</label>
                  <button  type="button" (click)="selectImageHomeActive()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-4">Upload Image</button>
                </div>
                <img *ngIf="showCropperHomeActive === false && imgShow === 'shown'" [src]="baseUrl+imageActive" class="m-t-5" style="width: 50%;">
                <div style="width: 400px;">
                  <image-cropper
                    [imageChangedEvent]="imageChangedEventHomeActive"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1"
                    [imageQuality] = "75"
                    format="png"
                    (imageCropped)="imageCroppedHomeActive($event)" *ngIf="showCropperHomeActive === true"></image-cropper>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn waves-effect  btn btn-light" data-dismiss="modal" (click)="closeCategoryModal()">Close</button>
          <button type="submit" *ngIf="addCat === true" (click)="submitCat()" (submit)="submitCat()" class="btn btn-primary waves-effect waves-light">Add</button>
          <button type="submit" *ngIf="addCat === false" (click)="updateCat()" (submit)="updateCat()" class="btn btn-primary waves-effect waves-light">Update</button>
        </div>
      </div>
    </form>
  </div>
</div>

<input type="file" accept="image/*" style="visibility: hidden;" class="marginInputHome" id="imgSelectHome" (change)="fileChangeEventHome($event)" />
<input type="file" accept="image/*" style="visibility: hidden;" class="marginInputHome" id="imgSelectHomeActive" (change)="fileChangeEventHomeActive($event)" />
