<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/">Kebabish</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/home">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/categories">Categories</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/menus">Menus</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/pictures">Pictures</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/timings">Timings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/news">News</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/admin/menus-order">Menus Order</a>
      </li>
<!--      <li class="nav-item dropdown">-->
<!--        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--          Dropdown-->
<!--        </a>-->
<!--        <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--          <a class="dropdown-item" href="#">Action</a>-->
<!--          <a class="dropdown-item" href="#">Another action</a>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <a class="dropdown-item" href="#">Something else here</a>-->
<!--        </div>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link disabled" href="#">Disabled</a>-->
<!--      </li>-->
    </ul>
<!--    <form class="form-inline my-2 my-lg-0">-->
<!--      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">-->
<!--      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>-->
<!--    </form>-->
    <div class="text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="logout()"><i class="fas fa-power-off mr-2"></i>Sign Out</button>
<!--      <div class="dropdown">-->
<!--        <button type="button" class="btn btn-outline-secondary dropdown-toggle" id="dropdownButton" data-toggle="dropdown">Dropdown</button>-->
<!--        <div class="dropdown-menu dropdown-menu-right">-->
<!--          <a class="dropdown-item" href="#">Link 1</a>-->
<!--          <a class="dropdown-item" href="#">Link 2</a>-->
<!--          <a class="dropdown-item" href="#">Link 3</a>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</nav>
