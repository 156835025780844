import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component';
import {LayoutsModule} from './layouts/layouts.module';
import { LoginComponent } from './login/login.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, SPINNER} from 'ngx-ui-loader';
import {HttpClientModule} from '@angular/common/http';
import {AdminModule} from './admin/admin.module';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#4d6abb',
  bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  bgsType: SPINNER.rectangleBounce,
  fgsColor: '#4d6abb',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  fgsType: SPINNER.ballScaleMultiple,
  // logoUrl: 'assets/images/loder-red.gif',
  pbColor: '#4d6abb',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    LayoutsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderRouterModule,
    HttpClientModule,
    AdminModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
