import { Component, OnInit } from '@angular/core';
import {AotSummaryResolver} from '@angular/compiler';
import {DataService} from '../Shared/data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Constant} from '../Shared/constant';
import set = Reflect.set;
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import {utc} from 'moment/moment';

declare var $: any;
declare var toastr: any;
declare var AOS: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  baseUrl = Constant.apiAdress;

  name = '';
  email = '';
  message = '';
  phone = '';
  type = 'CamdenHealth';
  mailData: any;
  topic = '';
  submit = false;

  // apis data
  homeData: any;
  timings: any;
  pictures: any;
  categories: any;
  menus: any;
  menusCopy: any;
  news: any;
  showMenu = false;
  isRestaurantOpen = 'CLOSED';
  ratingText = 1;
  activeCatText = '';
  activeCatId: any;

  constructor(private dataService: DataService, private ngxLoader: NgxUiLoaderService) {
    this.getHomeData();
    // this.getMenu();
    this.getCat();
    this.getPicture();
    this.getTimings();
    this.getNews();
  }

  ngOnInit(): void {

    AOS.init();
    // $('.services-slider').owlCarousel({
    //   dots: true,
    //   dotData: true,
    //   autoplay: false,
    //   lazyLoad: true,
    //   loop: false,
    //   responsiveClass: true,
    //   autoHeight: true,
    //   autoplayTimeout: 7000,
    //   smartSpeed: 800,
    //   nav: false,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     600: {
    //       items: 2,
    //     },
    //     1000: {
    //       items: 3,
    //     }
    //   }
    // });

    $('body').scrollspy({
      target: '.navbar',
      offset: 160
    });

    // setTimeout( () => {
    //   var nav = $('nav');
    //   // console.log('lll', nav.length());
    //   if (nav.length) {
    //     $('nav a').bind('click', () => {
    //       console.log('n', $(this));
    //       $('html, body').stop().animate({
    //         scrollTop: $($(this).attr('href')).offset().top - 100
    //       }, 1500, 'easeInOutExpo');
    //       // @ts-ignore
    //       // event.preventDefault();
    //     });
    //   }
    // }, 500);


    setInterval( () => {
      this.changeRatingText();
    }, 8000);

  }

  changeRatingText(): any {
    if (this.ratingText === 1) {
      this.ratingText = 2;
    } else if (this.ratingText === 2) {
      this.ratingText = 3;
    } else if (this.ratingText === 3) {
      this.ratingText = 1;
    }
  }

  getHomeData(): any {
    // this.ngxLoader.start();
    this.dataService.fetchHomeData().subscribe(
      (data) => {
        // this.ngxLoader.stop();
        this.homeData = data;
        // this.image = this.data.image;
      },
      (error) => {
        // this.ngxLoader.stop();
        // toastr.error(error.error.error);
      }
    );
  }
  getTimings(): any {
    this.dataService.fetchTimingData().subscribe(
      (data) => {
        this.timings = data;
        if (this.timings) {
          this.calcTime();
        }
      },
      (error) => {
      }
    );
  }
  getPicture(): any {
    this.dataService.fetchPictureData().subscribe(
      (data) => {
        this.pictures = data;
      },
      (error) => {
      }
    );
  }
  getCat(): any {
    this.dataService.fetchCatData().subscribe(
      (data) => {
        this.categories = data;
        if (this.categories) {
          this.updateMenus(this.categories[0]);
        }
      },
      (error) => {
      }
    );
  }
  getMenu(): any {
    this.dataService.fetchMenuData().subscribe(
      (data) => {
        this.menus = data;
        // this.menusCopy = data;
      },
      (error) => {
      }
    );
  }
  getNews(): any {
    this.dataService.fetchNewsData().subscribe(
      (data) => {
        this.news = data;
      },
      (error) => {
      }
    );
  }

  validateEmail(email: string): any {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSubmit(): any {


    if ( this.name === '' ) {
      toastr.error('Please enter name');
      return;
    }
    if ( this.email === '' ) {
      toastr.error('Please enter email');
      return;
    }
    if ( this.validateEmail(this.email) === false ) {
      toastr.error('Please enter valid email');
      return;
    }
    // if ( this.topic === '' ) {
    //   toastr.error('Please select topic');
    //   return;
    // }
    if ( this.message === '' ) {
      toastr.error('Please enter message');
      return;
    }
    // console.log(this.name, this.email, this.message, this.phone, this.topic);

    this.submit = true;
    this.dataService.sendEmail(this.name, this.email, this.message, this.phone, this.topic).subscribe(
      (data) => {
        toastr.success('Message has been send successfully');
        this.submit = false;
        this.mailData = data;
        this.name = '';
        this.email = '';
        this.message = '';
        this.phone = '';
        this.topic = '';
      },
      (error) => {
        toastr.error('Something went wrong, please try again later');
        this.submit = true;
      }
    );

  }

  onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  openNav(): any {
    // @ts-ignore
    document.getElementById('mySidebar').style.width = '250px';
  }

  closeNav(): any {

    // @ts-ignore
    document.getElementById('mySidebar').style.width = '0';
  }


  imageFun(imageUrl: any): any {
    // Get the modal
    // var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    // var img = document.getElementById("myImg");
    // var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");

    // @ts-ignore
    document.getElementById('myModal').style.display = 'block';
    // @ts-ignore
    document.getElementById('img01').src = imageUrl;

    // Get the <span> element that closes the modal
    // var span = document.getElementsByClassName("close")[0];

  }

  closeImageModal(): any {
    // @ts-ignore
    document.getElementById('myModal').style.display = 'none';
  }

  updateMenus(data: any): any {
    // console.log('abc', data);
    this.activeCatId = Number(data.id);
    if (data.description) {
      this.activeCatText = data.description;
    } else {
      this.activeCatText = '';
    }
    this.showMenu = false;
    this.menusCopy = [];
    for (let i = 0; i < data.category_menu.length; i++) {
      this.menusCopy.push(data.category_menu[i].menu);
    }
    // // console.log('id', id);
    // const array: any[] = [];
    // this.menusCopy = [];
    // this.showMenu = false;
    // // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < this.menus.length; i++) {
    //   // tslint:disable-next-line:prefer-for-of
    //   for (let j = 0; j < this.menus[i].category.length; j++) {
    //     if (this.menus[i].category[j] === id) {
    //       this.menusCopy.push(this.menus[i]);
    //     }
    //   }
    // }

    setTimeout( () => {
      // this.menusCopy = array;
      this.showMenu = true;
    }, 50);
  }


  calcTime(): any {
    let dateCheck = momentTz.tz(moment(), 'America/Tijuana').format('YYYY-MM-DDTHH:mm:ss.SSS');
    console.log('dateCheck', dateCheck);
    const dateObj = new Date(dateCheck);
    const weekday = dateObj.toLocaleString('default', { weekday: 'long' });
    console.log('weekday', weekday);
    if (this.timings.filter((n: { days: string; is_active: boolean; }) => n.days === weekday &&  n.is_active ).length > 0) {
      const checkingTime = this.timings.filter((n: { days: string; }) => n.days === weekday )[0];
      let openTime = checkingTime.opening_time;
      let closeTime = checkingTime.closing_time;

      let currentTime = moment(dateCheck, ["YYYY-MM-DDTHH:mm:ss.SSS"]).format("HH:mm");
      console.log('currentTime', currentTime);

      var openHour = moment(openTime, ["h:mm A"]).format("HH");
      console.log('openHour', openHour);
      var openMints = moment(openTime, ["h:mm A"]).format("mm");
      console.log('openMints', openMints);
      let open = ( Number(openHour) * 60 ) + Number(openMints);
      console.log('open', open);

      var closeHour = moment(closeTime, ["h:mm A"]).format("HH");
      console.log('closeHour', closeHour);
      var closeMints = moment(closeTime, ["h:mm A"]).format("mm");
      console.log('closeMints', closeMints);
      let close = ( Number(closeHour) * 60 ) + Number(closeMints);
      console.log('close', close);


      var currentHour = moment(currentTime, ["h:mm A"]).format("HH");
      console.log('currentHour', currentHour);
      var currentMints = moment(currentTime, ["h:mm A"]).format("mm");
      console.log('currentMints', currentMints);
      let current = ( Number(currentHour) * 60 ) + Number(currentMints);
      console.log('current', current);



      // const open = checkingTime.opening_time;
      // const close = checkingTime.closing_time;

      // let time = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
      // let time = momentTz.tz(dateCheck, 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/Tijuana').toISOString();

      // let time1 = new Date(time);
      // let time2 = time1.toLocaleString('en-US', { timeZone: 'America/Tijuana' });


      // let timeHours = moment(time).format('H');
      // let timeMints = moment(time, 'YYYY-MM-DDTHH:mm:ss.SSS').format('m');
      // console.log('timeHours1111111', Number(timeMints));
      // let totalMinits = (Number(timeHours) * 60) + Number(timeMints);
      // console.log('totalMinits', totalMinits);
      //
      //
      // let openHours = moment(open, 'YYYY-MM-DDTHH:mm:ss.SSS').format('H');
      // let openMints = moment(open, 'YYYY-MM-DDTHH:mm:ss.SSS').format('m');
      // let openMinits = (Number(openHours) * 60) + Number(openMints);
      // console.log('openMinits', openMinits);
      //
      // let closeHours = moment(close, 'YYYY-MM-DDTHH:mm:ss.SSS').format('H');
      // let closeMints = moment(close, 'YYYY-MM-DDTHH:mm:ss.SSS').format('m');
      // let closeMinits = (Number(closeHours) * 60) + Number(closeMints);
      // console.log('closeMinits', closeMinits);


      // let time2 = moment(time1, 'h:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS');
      // let utc = momentTz.tz(time2, 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/Tijuana').toISOString();

      // let utcDate = new Date(time).getTime();
      // let openTime = new Date(open).getTime();
      // let closeTime = new Date(close).getTime();
      //
      //
      //
      // console.log('Timestamp');
      // console.log('current', utcDate);
      // console.log('open', openTime);
      // console.log('close', closeTime);

      if (current >= open && current <= close) {
        console.log('yes');
        this.isRestaurantOpen = 'OPEN';
      } else {
        console.log('no');
        this.isRestaurantOpen = 'CLOSED';
      }
    } else {
      this.isRestaurantOpen = 'CLOSED';
      // console.log('n');
    }

  }

   currentFormat(value: any): any {
    const date = new Date(value);
    return date.toLocaleString('en-US', { timeZone: 'America/Tijuana' });
  }
}
