import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormBuilder} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';
import {Constant} from '../../Shared/constant';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.css']
})
export class PicturesComponent implements OnInit {

  baseUrl = Constant.apiAdress;
  // for pictures section
  croppedPictureHome: any;
  imageChangedEventPicture = '';
  showCropperPicture = false;
  picture = '';
  pictures: any;

  constructor(private fb: FormBuilder, private ngxLoader: NgxUiLoaderService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.getPicture();
  }

  getPicture(): any {
    // this.categories = [];
    this.ngxLoader.start();
    this.dataService.getPictureData().subscribe(
      (data) => {
        this.pictures = data;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  // for pictures
  imageCroppedPicture(event: ImageCroppedEvent): any {
    this.croppedPictureHome = event.base64;
  }
  fileChangeEventPicture(event: any): void {
    this.showCropperPicture = true;
    this.imageChangedEventPicture = event;
  }
  selectImagePicture(): any {
    $('#imgSelectPicture').val('');
    $('#imgSelectPicture').click();
  }
  submitPicturesData(): any {

    if ( !this.croppedPictureHome ) {
      toastr.error('Select Picture');
      return;
    }
    this.picture = this.croppedPictureHome;

    // console.log('picture data:', this.picture);

    const value = {
      image: this.picture
    };
    this.ngxLoader.start();
    this.dataService.addPictureData(value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Upload successfully');
        // this.closeCategoryModal();
        this.showCropperPicture = false;
        this.getPicture();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );

  }

  deletePicture(id: any): any {
    this.ngxLoader.start();
    this.dataService.delPictureData(id).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Delete successfully');
        this.getPicture();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }
}
