<div class="container">

  <div class="card mt-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Pictures</h4>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="mb-4">
            <label>Picture: <span class="text-danger">*</span> (Image Ratio: 3 / 2)</label>
            <button  type="button" (click)="selectImagePicture()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-4">Upload Image</button>
          </div>
          <!--            <img *ngIf="showCropperHome === false" src="{{settingData?.homepage_image}}" class="m-t-5" style="width: 150px;">-->
          <div style="width: 400px;">
            <image-cropper
              [imageChangedEvent]="imageChangedEventPicture"
              [maintainAspectRatio]="true"
              [aspectRatio]="3 / 2"
              [imageQuality] = "75"
              format="png"
              (imageCropped)="imageCroppedPicture($event)" *ngIf="showCropperPicture === true"></image-cropper>
          </div>
        </div>
      </div>

      <div class="row mt-2 text-right">
        <div class="col-12">
          <button type="button" class="btn btn-sm btn-primary" (click)="submitPicturesData()">Submit</button>
        </div>
      </div>

    </div>
  </div>

  <div class="card mt-4 ">
    <div class="card-body">
      <div class="row images">
        <div class="col-md-4 col-sm-6 col-12 containers mb-5" *ngFor="let data of pictures; let i = index">
          <img [src]="baseUrl+data?.image" alt="Picture" class="w-100">
          <div class="overlay">
            <a class="icon" title="Picture" (click)="deletePicture(data.id)">
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<input type="file" accept="image/*" style="visibility: hidden;" class="marginInputHome" id="imgSelectPicture" (change)="fileChangeEventPicture($event)" />
