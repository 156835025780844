import { Component, OnInit } from '@angular/core';
import {DataService} from '../../Shared/data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private dataService: DataService,
              private ngxLoader: NgxUiLoaderService,
              private router: Router) { }

  ngOnInit(): void {
  }


  logout(): any {
    this.ngxLoader.start();
    this.dataService.LogOut().subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.router.navigate(['admin/login']);
        window.localStorage.clear();
        // this.userData = data;
        // console.log('this.userData', this.userData);
        // window.localStorage.setItem('isAuthenticated', 'true');
        // window.localStorage.setItem('auth_token', this.userData.auth_token);
        // window.localStorage.setItem('email', this.userData.data.user.email);
        // window.localStorage.setItem('user', JSON.stringify(this.userData.data));
        // this.router.navigate(['/admin']);
      },
      (error) => {
        this.ngxLoader.stop();
        // toastr.error(error.error.error);
      }
    );
  }

}
