<div class="container">

  <div class="card my-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Menus Order</h4>
        </div>
      </div>


      <div class="row mt-4">
        <div class="col-4">
          <label>Categories</label>
          <select class="form-control" (change)="fetchCatMenus($event.target)" [(ngModel)]="currentCatId">
            <option value="">Select</option>
            <option [value]="data.id" *ngFor="let data of categories">{{data?.name}}</option>
          </select>
        </div>
      </div>

      <div class="mt-5">
        <table class="table">
          <thead>
          <tr>
            <th style="width: 7%">Sr #</th>
            <th style="width: 23%">Name</th>
            <th style="width: 50%">Description</th>
            <th style="width: 10%">Price</th>
            <th style="width: 10%">Order</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of catMenus;let i = index">
            <td>{{i+1}}</td>
            <td>{{data?.menu?.name}}</td>
            <td>{{data?.menu?.description}}</td>
            <td>{{data?.menu?.price}}</td>
            <td><input type="text" class="form-control" placeholder="Order" id="order{{i}}" [(ngModel)]="data.priority" /></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row text-right" *ngIf="catMenus.length > 0">
        <div class="col-12">
          <button class="btn btn-primary" type="button" (click)="updatePriority()">Update Priority</button>
        </div>
      </div>

    </div>
  </div>


</div>
