import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';


declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-timings',
  templateUrl: './timings.component.html',
  styleUrls: ['./timings.component.css']
})
export class TimingsComponent implements OnInit {

  timingFrom: FormGroup;
  days = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  timings: any;

  constructor(private fb: FormBuilder, private ngxLoader: NgxUiLoaderService,
              private dataService: DataService) {

    this.timingFrom = this.fb.group({
      timings: this.fb.array([])
    });

  }

  ngOnInit(): void {

    setTimeout( () => {
      $('.timepickers').timepicker({
        format: 'H:mm A',
        defaultTime: '00:00',
        minuteStep: 1,
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fas fa-angle-up',
          down: 'fas fa-angle-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-clock-o',
          clear: 'fa fa-trash-o'
        }
        // showMeridian: false,
        // use24hours: true
      });
    }, 100 );

    this.getTimings();
    // this.createTimings();
  }

  getTimings(): any {
    // this.categories = [];
    this.ngxLoader.start();
    this.dataService.getTimingData().subscribe(
      (data) => {
        this.timings = data;
        // console.log('this.timings ', this.timings.length);
        while (this.controlTimings.length) {
          this.controlTimings.removeAt(0);
        }
        if (this.timings) {
          for (let i = 0; i < this.timings.length; i++) {
            this.controlTimings.push(this.addTimings(this.timings[i]));
          }
        }
        setTimeout( () => {
          $('.timepickers').timepicker({
            // format: 'H:mm A',
            // defaultTime: '00:00',
            minuteStep: 1,
            icons: {
              time: 'fa fa-clock-o',
              date: 'fa fa-calendar',
              up: 'fas fa-angle-up',
              down: 'fas fa-angle-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-clock-o',
              clear: 'fa fa-trash-o'
            }
            // showMeridian: false,
            // use24hours: true
          });
        }, 500 );
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  addTimings(data: any): any {
    // let open = new Date(data.opening_time);
    // let close = new Date(data.closing_time);
    // let openTime =  open.toLocaleString('en-US', { timeZone: 'America/Tijuana' });
    // let closeTime =  close.toLocaleString('en-US', { timeZone: 'America/Tijuana' });
    // const openTimes = moment(openTime).format('H:mm A');
    // const closeTimes = moment(closeTime).format('H:mm A');
    return this.fb.group({
      days: [data.days],
      opening_time: [data.opening_time],
      closing_time: [data.closing_time],
      is_active: [data.is_active]
    });
  }

  get controlTimings(): any {
    return (this.timingFrom.get('timings') as FormArray);
  }

  // createTimings(): any {
  //   setTimeout( () => {
  //     for (let i = 0; i < 7; i++) {
  //       this.controlTimings.push(this.addTimings(i));
  //       $('#openingTime'+i).timepicker('refresh');
  //       $('#closingTime'+i).timepicker('refresh');
  //     }
  //   }, 200);
  //
  //   setTimeout( () => {
  //     $('.timepickers').timepicker({
  //       format: 'H:mm',
  //       defaultTime: '00:00',
  //       minuteStep: 1,
  //       // timeZone: 'pst',
  //       icons: {
  //         time: 'fa fa-clock-o',
  //         date: 'fa fa-calendar',
  //         up: 'fas fa-angle-up',
  //         down: 'fas fa-angle-down',
  //         previous: 'fa fa-chevron-left',
  //         next: 'fa fa-chevron-right',
  //         today: 'fa fa-clock-o',
  //         clear: 'fa fa-trash-o'
  //       }
  //       // showMeridian: false,
  //       // use24hours: true
  //     });
  //   }, 500 );
  // }

  submitTiming(): any {

    const timingsArray = this.controlTimings.value;
    for (let i = 0; i < timingsArray.length; i++) {
      let open_time, close_time;
      // let openTime = momentTz.tz($('#openingTime' + i).val(), 'H:mm A', 'America/Tijuana').toISOString();
      // let closeTime = momentTz.tz($('#closingTime' + i).val(), 'H:mm A', 'America/Tijuana').toISOString();
      // timingsArray[i].opening_time = openTime;
      // timingsArray[i].closing_time = closeTime;

      timingsArray[i].opening_time = $('#openingTime'+i).val();
      // open_time = $('#openingTime'+i).val();
      // open_time = moment(open_time, 'h:mm A');
      // open_time = moment(open_time, 'h:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS');
      // timingsArray[i].opening_time = momentTz.tz(open_time, 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/Tijuana').toISOString();

      timingsArray[i].closing_time = $('#closingTime'+i).val();
      // close_time = $('#closingTime'+i).val();
      // close_time = moment(close_time, 'h:mm A');
      // close_time = moment(close_time, 'h:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS');
      // timingsArray[i].closing_time = momentTz.tz(close_time, 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/Tijuana').toISOString();

    }
    this.ngxLoader.start();
    this.dataService.addTimingData(timingsArray).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Upload successfully');
        // this.closeCategoryModal();
        this.getTimings();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

}
