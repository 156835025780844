import { Component, OnInit } from '@angular/core';
import {DataService} from '../Shared/data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userName = '';
  password = '';
  userData: any;

  constructor(private dataService: DataService, private ngxLoader: NgxUiLoaderService,
              private router: Router) {
    window.scrollTo(0, 0);

    if (window.localStorage.getItem('isAuthenticated') === 'true') {
      this.router.navigate(['/admin/home']);
    }
    // if ( this.router.url === '/login') {
    //   router.navigate(['/']);
    // }

  }

  ngOnInit(): void {

  }

  login(): any {
    if (this.userName === '') {
      toastr.error('Enter username');
      return;
    }
    if (this.password === '') {
      toastr.error('Enter password');
      return;
    }
    this.ngxLoader.start();
    this.dataService.Login(this.userName, this.password).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.userData = data;
        // console.log('this.userData', this.userData);
        window.localStorage.setItem('isAuthenticated', 'true');
        window.localStorage.setItem('auth_token', this.userData.auth_token);
        // window.localStorage.setItem('email', this.userData.data.user.email);
        // window.localStorage.setItem('user', JSON.stringify(this.userData.data));
        this.router.navigate(['/admin/home']);
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

}
