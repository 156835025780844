<div class="container">

  <div class="card mt-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Menus</h4>
        </div>
      </div>


      <div class="row mb-3 text-right">
        <div class="col-12">
          <button class="btn btn-sm btn-primary" type="button" (click)="openMenuModal()">Add Menu</button>
        </div>
      </div>


      <div>
        <table class="table">
          <thead>
          <tr>
            <th style="width: 7%">Sr #</th>
            <th style="width: 23%">Name</th>
            <th style="width: 50%">Desc</th>
            <th style="width: 10%">Price</th>
            <th style="width: 10%">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of menus; let i = index">
            <td>{{i+1}}</td>
            <td>{{data?.name}}</td>
            <td>{{data?.description}}</td>
            <td>{{data?.price}}</td>
            <td><span class="mr-3 pointer" (click)="editMenu(data)"><i class="fas fa-edit"></i></span>
              <span class="pointer"><i class="fas fa-trash-alt" (click)="deleteMenu(data.id)"></i></span></td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

</div>

<!-- add discount type modal start-->
<div id="menuModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static" style="display: none;">
  <div class="modal-dialog modal-lg">
    <form [formGroup]="menuForm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" *ngIf="addMenu === true">Add Menu</h4>
          <h4 class="modal-title" *ngIf="addMenu === false">Update Menu</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeMenuModal()">×</button>
        </div>
        <div class="modal-body">
          <div class="row">

            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group">
                <label class="control-label">Name</label>
                <input type="text" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': isSubmit && f.name.errors }" class="form-control">
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group">
                <label class="control-label">Price</label>
                <input type="text" placeholder="Price" formControlName="price" [ngClass]="{ 'is-invalid': isSubmit && f.price.errors }" class="form-control">
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group">
                <label class="control-label">Categories</label>
                <div [ngClass]="{ 'is-invalid': isSubmit && f.category.errors }">
                  <select formControlName="category" class="form-control selectpicker" id="cat" multiple data-live-search="true">
                    <option value="" disabled>Select</option>
                   <option *ngFor="let data of categories" [value]="data.id">{{data?.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <label class="control-label">Description</label>
                <textarea placeholder="Description" formControlName="description" [ngClass]="{ 'is-invalid': isSubmit && f.description.errors }" class="form-control"></textarea>
              </div>
            </div>


            <div class="col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <div class="mb-4">
                  <label>Menu Image<span class="text-danger">*</span> (Image Ratio: 3.5 / 2.5)</label>
                  <button  type="button" (click)="selectImageHome()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-4">Upload Image</button>
                </div>
                <img *ngIf="showCropperImage === false && imgShow === 'shown'" [src]="baseUrl+image" class="m-t-5" style="width: 50%;">
<!--                <img *ngIf="showCropperHome === false && imgShow === 'shown'" [src]="baseUrl+image" class="m-t-5" style="width: 50%;">-->
                <div style="width: 400px;">
                  <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="3.5 / 2.5"
                    [imageQuality] = "75"
                    format="png"
                    (imageCropped)="imageCroppedHome($event)" *ngIf="showCropperImage === true"></image-cropper>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn waves-effect  btn btn-light" data-dismiss="modal" (click)="closeMenuModal()">Close</button>
          <button type="submit" *ngIf="addMenu === true" (click)="submitMenu()" (submit)="submitMenu()" class="btn btn-primary waves-effect waves-light">Add</button>
          <button type="submit" *ngIf="addMenu === false" (click)="updateMenu()" (submit)="updateMenu()" class="btn btn-primary waves-effect waves-light">Update</button>
        </div>
      </div>
    </form>
  </div>
</div>

<input type="file" accept="image/*" style="visibility: hidden;" class="marginInputHome" id="imgSelectHome" (change)="fileChangeEventHome($event)" />

