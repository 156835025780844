import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-update-category-menu-sorting',
  templateUrl: './update-category-menu-sorting.component.html',
  styleUrls: ['./update-category-menu-sorting.component.css']
})
export class UpdateCategoryMenuSortingComponent implements OnInit {

  categories: any;
  currentCatId = '';
  catMenus: any = [];

  constructor(private fb: FormBuilder, private ngxLoader: NgxUiLoaderService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.getCat();
  }

  getCat(): any {
    this.ngxLoader.start();
    this.dataService.getCatData().subscribe(
      (data) => {
        this.categories = data;
        this.ngxLoader.stop();
        setTimeout( () => {
          $('#cat').selectpicker('refresh');
        }, 300);
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  fetchCatMenus(value: any): any {
    if (value.value === '') {
      this.catMenus = [];
      return;
    }
    if (value.value) {
      this.catMenus = [];
      this.ngxLoader.start();
      this.dataService.getCatMenuData(value.value).subscribe(
        (data) => {
          this.catMenus = data;
          if (data) {
            for (let i = 0; i < this.catMenus.length; i++) {
              // @ts-ignore
              $('#order' + i).val(this.catMenus[i].priority);
            }
          } else {
            this.catMenus = [];
          }
          this.ngxLoader.stop();
        },
        (error) => {
          this.ngxLoader.stop();
          // toastr.error(error.error.error);
        }
      );
    }
  }

  updatePriority(): any {
    let array = [];
    let obj = {};
    for (let i = 0; i < this.catMenus.length; i ++) {
      obj = {
        id: this.catMenus[i].id,
        priority: $('#order' + i).val(),
      };
      array.push(obj);
    }
    // console.log('array', array);
    this.ngxLoader.start();
    this.dataService.updateCatMenuData(array).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Update Category Menus');
        this.currentCatId = '';
        this.catMenus = [];
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }
}
