import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Constant} from '../../Shared/constant';


declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsForm: FormGroup;
  isSubmit = false;

  baseUrl = Constant.apiAdress;

  // for home main image
  croppedImageHome: any;
  imageChangedEventHome = '';
  showCropperHome = false;

  data: any;
  image = '';

  constructor(private fb: FormBuilder, private dataService: DataService,
              private ngxLoader: NgxUiLoaderService) {
    this.newsForm = this.fb.group({
      heading: ['', Validators.required],
      description: ['', Validators.required],
      today_special: ['', Validators.required],
      image: [''],
      // is_update: [false],
      is_active: [true],
    });
  }

  ngOnInit(): void {
    this.getNewsData();
  }

  getNewsData(): any {
    this.ngxLoader.start();
    this.dataService.getNewsData().subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.data = data;
        this.showCropperHome = false;
        this.newsForm.patchValue({
          heading: this.data.heading,
          description: this.data.description,
          today_special: this.data.today_special,
        });
        this.image = this.data.image;
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }



  // for main image
  imageCroppedHome(event: ImageCroppedEvent): any {
    this.croppedImageHome = event.base64;
  }
  fileChangeEventHome(event: any): void {
    this.showCropperHome = true;
    this.imageChangedEventHome = event;
  }
  selectImageHome(): any {
    $('#imgSelectHome').val('');
    $('#imgSelectHome').click();
  }

  submitNewsData(): any {
    this.isSubmit = true;
    if (this.newsForm.invalid) {
      return;
    }

    // if ( !this.image || this.image === '' ) {
    //   toastr.error('Select home Image');
    //   return;
    // }
    if ( !this.croppedImageHome && (!this.image || this.image === '') ) {
      toastr.error('Select Image');
      return;
    }

    if ( this.croppedImageHome ) {
      this.newsForm.patchValue({
        image: this.croppedImageHome,
        // is_update: false
      });
    } else {
      this.newsForm.patchValue({
        image: this.image,
        // is_update: true,
      });
    }
    // console.log('data:', this.homeDataForm.value);
    this.ngxLoader.start();
    this.dataService.addNewsData(this.newsForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.image = '';
        this.croppedImageHome = '';
        this.newsForm.reset();
        this.isSubmit = false;
        toastr.success('Save successfully');
        this.getNewsData();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  get f(): any { return this.newsForm.controls; }

}
