import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';
import {Constant} from '../../Shared/constant';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  catForm: FormGroup;
  isSubmitCat = false;
  addCat = true;
  categories: any;
  updateCatId: any;

  croppedImageHome: any;
  imageChangedEventHome = '';
  showCropperHome = false;

  croppedImageHomeActive: any;
  imageChangedEventHomeActive = '';
  showCropperHomeActive = false;

  baseUrl = Constant.apiAdress;
  image = '';
  imageActive = '';
  imgShow = 'notShown';

  constructor(private fb: FormBuilder, private ngxLoader: NgxUiLoaderService,
              private dataService: DataService) {
    this.catForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      image: [''],
      active_image: ['']
    });
  }

  ngOnInit(): void {
    this.getCat();
  }

  getCat(): any {
    // this.categories = [];
    this.ngxLoader.start();
    this.dataService.getCatData().subscribe(
      (data) => {
        if (data) {
          this.categories = data;
          for (let i = 0; i < this.categories.length; i++) {
            $('#ordersSorting' + i).val(this.categories[i].priority);
            // console.log('1', this.categories[i].priority);
          }
        } else {
          this.categories = [];
        }

        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
        // toastr.error(error.error.error);
      }
    );
  }

  openCategoryModal(): any {
    this.imgShow = 'notShown';
    this.addCat = true;
    $('#catModal').modal('show');
  }
  closeCategoryModal(): any {
    $('#catModal').modal('hide');
    this.catForm.reset();
    this.isSubmitCat = false;
    $('#imgSelectHome').val('');
    $('#imgSelectHomeActive').val('');
    this.croppedImageHome = '';
    this.imageChangedEventHome = '';
    this.croppedImageHomeActive = '';
    this.imageChangedEventHomeActive = '';
  }
  editCat(data: any): any {
    // console.log(data);
    this.updateCatId = data.id;
    this.catForm.patchValue({
      name: data.name,
      description: data.description,
      image: data.image,
      active_image: data.active_image
    });
    // if (data.image.charAt(0) === '/') {
      // console.log('here');
      // this.image = data.image.substring(1);
    // } else {

    this.image = data.image;
    this.imageActive = data.active_image;

    // }
    this.imgShow = 'shown';
    this.showCropperHome = false;
    this.showCropperHomeActive = false;
    this.addCat = false;
    $('#catModal').modal('show');
  }

  // for cat image
  imageCroppedHome(event: ImageCroppedEvent): any {
    this.croppedImageHome = event.base64;
  }
  fileChangeEventHome(event: any): void {
    this.showCropperHome = true;
    this.imageChangedEventHome = event;
  }
  selectImageHome(): any {
    $('#imgSelectHome').val('');
    $('#imgSelectHome').click();
  }

  // for cat active image
  imageCroppedHomeActive(event: ImageCroppedEvent): any {
    this.croppedImageHomeActive = event.base64;
  }
  fileChangeEventHomeActive(event: any): void {
    this.showCropperHomeActive = true;
    this.imageChangedEventHomeActive = event;
  }
  selectImageHomeActive(): any {
    $('#imgSelectHomeActive').val('');
    $('#imgSelectHomeActive').click();
  }


  submitCat(): any {

    this.isSubmitCat = true;
    if (this.catForm.invalid) {
      return;
    }

    if ( !this.croppedImageHome || !this.croppedImageHomeActive ) {
      toastr.error('Select Picture');
      return;
    }

    this.catForm.patchValue({
      image: this.croppedImageHome,
      active_image: this.croppedImageHomeActive
    });

    this.ngxLoader.start();
    this.dataService.addCatData(this.catForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Save successfully');
        this.closeCategoryModal();
        this.showCropperHome = false;
        this.getCat();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  updateCat(): any {

    this.isSubmitCat = true;
    if (this.catForm.invalid) {
      return;
    }

    if ( this.croppedImageHome ) {
      this.catForm.patchValue({
        image: this.croppedImageHome,
      });
    }

    if ( this.croppedImageHomeActive ) {
      this.catForm.patchValue({
        active_image: this.croppedImageHomeActive
      });
    }

    this.ngxLoader.start();
    this.dataService.updateCatData(this.updateCatId, this.catForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Update successfully');
        this.closeCategoryModal();
        this.showCropperHome = false;
        this.getCat();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );

  }

  deleteCat(id: any): any {
    this.ngxLoader.start();
    this.dataService.delCatData(id).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Delete successfully');
        this.getCat();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  dataURLtoFile(dataurl: any, filename: any): any {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  get f(): any { return this.catForm.controls; }

  updateOrder(): any {
    // console.log('order:', this.categories);

    var array = [];
    let obj = {};
    for (let i = 0; i < this.categories.length; i ++) {
      obj = {
        id: this.categories[i].id,
        priority: $('#ordersSorting' + i).val(),
      };
      array.push(obj);
    }
    this.ngxLoader.start();
    this.dataService.updateCatOrderData(array).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Update Categories Order');
        this.closeCategoryModal();
        this.showCropperHome = false;
        this.getCat();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }
}
