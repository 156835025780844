<div class="container">

  <div class="card mt-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Home Data</h4>
        </div>
      </div>

      <form [formGroup]="homeDataForm" (ngSubmit)="submitHomeData()">

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label>Home Text</label>
              <input type="text" class="form-control" placeholder="Text" formControlName="text" [ngClass]="{ 'is-invalid': isSubmit && f.text.errors }">
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <div class="mb-4">
                <label>Home Image<span class="text-danger">*</span> (Image Ratio: 5 / 3)</label>
                <button  type="button" (click)="selectImageHome()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-4">Upload Image</button>
              </div>
              <img *ngIf="showCropperHome === false" [src]="baseUrl+image" alt="Image" class="m-t-5" style="width: 40%;">
              <div style="width: 400px;">
                <image-cropper
                  [imageChangedEvent]="imageChangedEventHome"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="5 / 3"
                  [imageQuality] = "75"
                  format="png"
                  (imageCropped)="imageCroppedHome($event)" *ngIf="showCropperHome === true"></image-cropper>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-2 text-right">
          <div class="col-12">
            <button class="btn btn-primary btn-sm" type="submit">Submit</button>
          </div>
        </div>

      </form>

    </div>
  </div>

</div>


<input type="file" accept="image/*" style="visibility: hidden;" class="marginInputHome" id="imgSelectHome" (change)="fileChangeEventHome($event)" />
