import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constant} from './constant';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  Login(username: any, password: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/login', { username, password },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  LogOut(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/logout?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }


  // home
  addHomeData(value: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('is_update', value.is_update);
    formData.append('text', value.text);
    formData.append('image', value.image);
    return this.http.post(Constant.apiAdress + '/kebabish/home_data?auth_token=' + window.localStorage.getItem('auth_token'), formData,
      { headers: new HttpHeaders({})
      });
  }

  getHomeData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/home_data?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  // categories
  addCatData(value: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/category_list?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  getCatData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/category_list?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  updateCatData(id: any, value: any): Observable<any> {
    return this.http.put(Constant.apiAdress + '/kebabish/category_detail?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id, {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  updateCatOrderData(value: any): Observable<any> {
    return this.http.put(Constant.apiAdress + '/kebabish/set_category_priority?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  delCatData(id: any): Observable<any> {
    return this.http.delete(Constant.apiAdress + '/kebabish/category_detail?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  // menus
  addMenuData(value: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/menu_list?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  getMenuData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/menu_list?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  updateMenuData(id: any, value: any): Observable<any> {
    return this.http.put(Constant.apiAdress + '/kebabish/menu_detail?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id, {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  delMenuData(id: any): Observable<any> {
    return this.http.delete(Constant.apiAdress + '/kebabish/menu_detail?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      });
  }

  // pictures
  addPictureData(value: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/picture_list?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  getPictureData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/picture_list?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  updatePictureData(id: any, value: any): Observable<any> {
    return this.http.put(Constant.apiAdress + '/kebabish/picture_detail?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id, {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  delPictureData(id: any): Observable<any> {
    return this.http.delete(Constant.apiAdress + '/kebabish/picture_list?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      });
  }

  // timings

  addTimingData(value: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/timing_list?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
    }, { headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    });
  }

  getTimingData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/timing_list?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  // home
  addNewsData(value: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/news_list?auth_token=' + window.localStorage.getItem('auth_token'), {
      value
      },
      { headers: new HttpHeaders({})
      });
  }

  getNewsData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/news_list?auth_token=' + window.localStorage.getItem('auth_token'),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  // update cat menus
  updateCatMenuData(value: any): Observable<any> {
    return this.http.put(Constant.apiAdress + '/kebabish/set_category_menu_priority?auth_token=' + window.localStorage.getItem('auth_token'), {
        value
      },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }
  getCatMenuData(id: any): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/get_category_menu?auth_token=' + window.localStorage.getItem('auth_token') + '&id=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }


  // get calls for website

  fetchHomeData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/home',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }
  fetchCatData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/category',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }
  fetchMenuData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/menu',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }
  fetchPictureData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/picture',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }
  fetchTimingData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/timing',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }
  fetchNewsData(): Observable<any> {
    return this.http.get(Constant.apiAdress + '/kebabish/news',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
  }

  sendEmail(name: any, email: any, message: any, phone: any, topic: any): Observable<any> {
    return this.http.post(Constant.apiAdress + '/kebabish/contactus', JSON.stringify({
        full_name: name, email, message, contact: phone, topic }),
    { headers: new HttpHeaders({ 'Content-Type': 'application/json'})});
  }

}
