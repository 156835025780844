import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdminComponent} from './admin/admin.component';
import {MenusComponent} from './menus/menus.component';
import {TimingsComponent} from './timings/timings.component';
import {PicturesComponent} from './pictures/pictures.component';
import {CategoriesComponent} from './categories/categories.component';
import {NewsComponent} from './news/news.component';
import {UpdateCategoryMenuSortingComponent} from './update-category-menu-sorting/update-category-menu-sorting.component';

const routes: Routes = [
  { path: 'home', component: AdminComponent },
  { path: 'menus', component: MenusComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'pictures', component: PicturesComponent },
  { path: 'timings', component: TimingsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'menus-order', component: UpdateCategoryMenuSortingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
