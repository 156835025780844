import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../../Shared/data.service';
import {Constant} from '../../Shared/constant';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {

  baseUrl = Constant.apiAdress;
  menuForm: FormGroup;
  isSubmit = false;
  addMenu = true;
  menus: any;
  categories: any;
  image = '';
  imgShow = 'notShown';

  croppedImage: any;
  imageChangedEvent = '';
  showCropperImage = false;
  updateMenuId: any;

  constructor(private fb: FormBuilder, private ngxLoader: NgxUiLoaderService,
              private dataService: DataService) {
    this.menuForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      price: ['', Validators.required],
      category : ['', Validators.required],
      image: [''],
    });
  }

  ngOnInit(): void {

    this.getCat();
    this.getMenu();

    $('selectpicker').selectpicker();
  }

  getCat(): any {
    // this.categories = [];
    this.ngxLoader.start();
    this.dataService.getCatData().subscribe(
      (data) => {
        this.categories = data;
        this.ngxLoader.stop();
        setTimeout( () => {
          $('#cat').selectpicker('refresh');
        }, 300);
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  getMenu(): any {
    // this.categories = [];
    this.ngxLoader.start();
    this.dataService.getMenuData().subscribe(
      (data) => {
        this.menus = data;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }

  // for main image
  imageCroppedHome(event: ImageCroppedEvent): any {
    this.croppedImage = event.base64;
  }
  fileChangeEventHome(event: any): void {
    this.showCropperImage = true;
    this.imageChangedEvent = event;
  }
  selectImageHome(): any {
    $('#imgSelectHome').val('');
    $('#imgSelectHome').click();
  }


  openMenuModal(): any {
    setTimeout( () => {
      $('#cat').selectpicker('refresh');
    }, 200);
    this.imgShow = 'notShown';
    this.addMenu = true;
    $('#menuModal').modal('show');
    // setTimeout( () => {
    //   $('selectpicker').selectpicker();
    // }, 500);
  }
  closeMenuModal(): any {
    $('#menuModal').modal('hide');
    this.menuForm.reset();
    this.isSubmit = false;
    $('#imgSelectHome').val('');
    this.croppedImage = '';
    this.imageChangedEvent = '';
  }
  editMenu(data: any): any {

    this.updateMenuId = data.id;
    this.menuForm.patchValue({
      name: data.name,
      image: data.image,
      description: data.description,
      price: data.price,
      category : data.category,
    });
    setTimeout( () => {
      $('#cat').selectpicker('refresh');
    }, 200);
    // if (data.image.charAt(0) === '/') {
    // console.log('here');
    // this.image = data.image.substring(1);
    // } else {
    this.image = data.image;
    this.imgShow = 'shown';
    // }
    // this.imgShow = 'shown';
    // this.showCropperHome = false;

    this.addMenu = false;
    this.showCropperImage = false;
    $('#menuModal').modal('show');
  }

  submitMenu(): any {
    this.isSubmit = true;
    if (this.menuForm.invalid) {
      return;
    }

    // if ( !this.croppedImage ) {
    //   toastr.error('Select Picture');
    //   return;
    // }

    if ( this.croppedImage ) {
      this.menuForm.patchValue({
        image: this.croppedImage
      });
    }

    this.ngxLoader.start();
    this.dataService.addMenuData(this.menuForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Save successfully');
        this.closeMenuModal();
        this.showCropperImage = false;
        this.getMenu();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );

  }

  updateMenu(): any {
    this.isSubmit = true;
    if (this.menuForm.invalid) {
      return;
    }

    if ( this.croppedImage ) {
      this.menuForm.patchValue({
        image: this.croppedImage
      });
    }

    this.ngxLoader.start();
    this.dataService.updateMenuData(this.updateMenuId, this.menuForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Update successfully');
        this.closeMenuModal();
        this.showCropperImage = false;
        this.getMenu();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );

  }

  deleteMenu(id: any): any {
    this.ngxLoader.start();
    this.dataService.delMenuData(id).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Delete successfully');
        this.getMenu();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );

  }

  get f(): any { return this.menuForm.controls; }
}
