import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../Shared/data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Constant} from '../../Shared/constant';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  baseUrl = Constant.apiAdress;

  // homeData = {
  //   text: '',
  //   image: '',
  // };

  homeDataForm: FormGroup;
  isSubmit = false;

  // for home main image
  croppedImageHome: any;
  imageChangedEventHome = '';
  showCropperHome = false;

  data: any;
  image = '';



  constructor(private fb: FormBuilder, private dataService: DataService,
              private ngxLoader: NgxUiLoaderService) {
    this.homeDataForm = this.fb.group({
      text: ['', Validators.required],
      image: [''],
      is_update: [false],
    });
  }

  ngOnInit(): void {
    this.getHomeData();
  }

  getHomeData(): any {
    this.ngxLoader.start();
    this.dataService.getHomeData().subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.data = data;
        this.showCropperHome = false;
        this.homeDataForm.patchValue({
          text: this.data.text
        });
        // if (this.data.image.charAt(0) === '/') {
        //   this.data.image = this.data.image.substring(1);
        // }
        this.image = this.data.image;
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }



  // for main image
  imageCroppedHome(event: ImageCroppedEvent): any {
    this.croppedImageHome = event.base64;
  }
  fileChangeEventHome(event: any): void {
    this.showCropperHome = true;
    this.imageChangedEventHome = event;
  }
  selectImageHome(): any {
    $('#imgSelectHome').val('');
    $('#imgSelectHome').click();
  }

  submitHomeData(): any {
    this.isSubmit = true;
    if (this.homeDataForm.invalid) {
      return;
    }

    // if ( !this.image || this.image === '' ) {
    //   toastr.error('Select home Image');
    //   return;
    // }
    if ( !this.croppedImageHome && (!this.image || this.image === '') ) {
      toastr.error('Select home Image');
      return;
    }

    if ( this.croppedImageHome ) {
      const imgObj = this.dataURLtoFile(this.croppedImageHome, 'image.png');
      // console.log('a', imgObj);
      this.homeDataForm.patchValue({
        image: imgObj,
        is_update: false
      });
    } else {
      this.homeDataForm.patchValue({
        image: this.image,
        is_update: true,
      });
    }
    // console.log('data:', this.homeDataForm.value);
    this.ngxLoader.start();
    this.dataService.addHomeData(this.homeDataForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.image = '';
        this.croppedImageHome = '';
        this.homeDataForm.reset();
        this.isSubmit = false;
        toastr.success('Save successfully');
        this.getHomeData();
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.error);
      }
    );
  }
  dataURLtoFile(dataurl: any, filename: any): any {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  get f(): any { return this.homeDataForm.controls; }

}
