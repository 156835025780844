import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { MenusComponent } from './menus/menus.component';
import { AdminComponent } from './admin/admin.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import { CategoriesComponent } from './categories/categories.component';
import { PicturesComponent } from './pictures/pictures.component';
import { TimingsComponent } from './timings/timings.component';
import { NewsComponent } from './news/news.component';
import { UpdateCategoryMenuSortingComponent } from './update-category-menu-sorting/update-category-menu-sorting.component';


@NgModule({
  declarations: [MenusComponent, AdminComponent, CategoriesComponent, PicturesComponent, TimingsComponent, NewsComponent, UpdateCategoryMenuSortingComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ImageCropperModule,
    ReactiveFormsModule
  ]
})
export class AdminModule { }
