<script src="https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.11/moment-timezone-with-data-2010-2020.min.js"></script>

<!-- social Bar -->
<section class="container-fluid">
  <div class="row">
    <div class="col-12 mb-1 text-right">
      <span style="font-size: 15px;" class="mx-1">Follow Us:</span>
      <span style="font-size: 20px;" class="mx-2"><a href="https://www.facebook.com/kebabishsd" target="_blank"><img src="assets/images/fbc.png" style="height: 20px" alt="Facebook"></a></span>
      <span  style="font-size: 20px;" class="mx-2"><a href="https://www.instagram.com/kebabishsd/" target="_blank"><img src="assets/images/insta.png" style="height: 20px" alt="Instagram"></a></span>
    </div>
  </div>
</section>

<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-expand-md navbar-light bg-transparent pr-0 mt-3 d-none d-sm-none d-md-flex" style="padding-left: 42px">
  <!--  <a class="navbar-brand brand-text">IrvineTek.</a>-->
  <a class="navbar-brand brand-text">
    <img src="assets/images/KEBABISH-LOGO-3-green-tie-250x200.png" alt="" style="    position: absolute;top: -15px;left: 0;">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ml-auto">
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" href="#home">Home</a>-->
<!--      </li>-->
      <li class="nav-item">
        <a class="nav-link" href="#order">Order</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#menus">Menu</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#catering">Catering</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#meat">Our Meat</a>
      </li>
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" href="#picture">Pictures</a>-->
<!--      </li>-->
      <li class="nav-item">
        <a class="nav-link" href="#contact-us">Contact Us</a>
      </li>

    </ul>
  </div>
</nav>

<!--Sidebar section-->
<section class="d-block d-sm-block d-md-none">
<div id="mySidebar" class="sidebar">
  <a class="closebtn" (click)="closeNav()">×</a>
  <a (click)="closeNav()" href="#order">Order</a>
  <a (click)="closeNav()" href="#menus">Menu</a>
  <a (click)="closeNav()" href="#catering">Catering</a>
  <a (click)="closeNav()" href="#meat">Our Meat</a>
<!--  <a (click)="closeNav()" href="#picture">Pictures</a>-->
  <a (click)="closeNav()" href="#contact-us">Contact Us</a>
</div>

  <div class="d-flex flex-row">
    <div style="flex-basis: 20%;text-align: center;position: relative">
      <img src="assets/images/KEBABISH-LOGO-3-green-tie-250x200.png" class="img-fluid sidebar-logo" alt="Logo">
    </div>
    <div style="flex-basis: 60%;">
<!--      <img src="assets/images/uber-2752045-2284862.png" style="width: 50px;height: 50px" alt="Image">-->
<!--      <h3 class="text-white pt-2">Kebabish</h3>-->

    </div>
    <div style="flex-basis: 20%;text-align: right;">
      <button class="openbtn" (click)="openNav()">☰</button>
    </div>
  </div>

</section>


<!-- Home Section -->
<section id="home" class="container-fluid home-Section" [style.background-image]="'url('+baseUrl+homeData?.image+')'">

  <div class="container content-main text-center">
    <div class="d-flex justify-content-center">
      <h2 class="main-text" data-aos="fade-up" data-aos-duration="3000">{{homeData?.text}}</h2>
    </div>
<!--    <p class="text-center text-white" style=""><a style="text-decoration: none;color: white" routerLink="/camden-blog">Find out more...</a></p>-->
  </div>
</section>
<!--d-lg-block d-md-block d-sm-none d-none-->
<div class="text-right" style="text-align: right!important;">
  <img src="assets/images/100-zabiha-halal.png" class="img-fluid halal-img" alt="Halal Image">
</div>


<!--Order Section-->
<section id="order" class="container-fluid order-section pr-lg-5 pr-md-4 pr-sm-4 pl-lg-5 pl-md-4 pl-sm-4 pl-0">

  <div class="row text-center">
    <div class="col">
<!--      <h3>Order Now</h3>-->
      <h3>We are currently <span [ngClass]="isRestaurantOpen === 'OPEN' ? 'color-green' : 'color-red' ">{{isRestaurantOpen}}</span></h3>
    </div>
  </div>


  <div class="row mx-auto mt-4">
    <div class="col-lg-6 col-md-6 col-sm-6 col-12 pr-lg-0 pr-md-0 pr-sm-0 pr-0 icons-area">
      <div class="pb-2 pb-sm-0" style="border: 1px solid;border-radius: 3px;border-color: hsla(0,0%,80%,.26);height: 100% !important;">
        <div class="text-center" style="margin-top: 14px">
<!--          <p style="font-size: 18px;" class="mt-2">We are currently <span [ngClass]="isRestaurantOpen === 'OPEN' ? 'color-green' : 'color-red' ">{{isRestaurantOpen}}</span></p>-->
          <p style="font-size: 18px;" class="mt-2">Order Now</p>



          <div class="">
            <p class="font-weight-bold mb-1">Takeaway or Dine-In</p>
            <div class="d-flex pickup-dineIn justify-content-center">
              <div ><a href="https://www.clover.com/online-ordering/kebabish-san-diego" target="_blank"><img src="assets/images/Chrome.png" alt="Image" class="img-fluid"></a><div class="take-away-text online-text">Online</div></div>
              <div ><a href="tel:858-597-0640"><img src="assets/images/Pickup%20-%20Phone1.png" alt="Image" class="img-fluid pickup-Image"></a><div class="take-away-text"><a href="tel:858-597-0640" style="white-space: nowrap;clear: both;display: inline-block;overflow: hidden;">858-597-0640</a></div></div>
            </div>
          </div>


          <p class="font-weight-bold mb-1 mt-4">Delivery</p>
          <div class="d-flex takeaway">
            <div ><a href="https://www.ubereats.com/san-diego/food-delivery/kebabish/UvAmZbWjS2KXGe-0MDTPvg" target="_blank"><img src="assets/images/UberEats.png" alt="Image" class="img-fluid"></a></div>
            <div ><a href="https://www.doordash.com/store/kebabish-san-diego-656486" target="_blank"><img src="assets/images/DoorDash.png" alt="Image" class="img-fluid"></a></div>
            <div ><a href="https://postmates.com/merchant/kebabish-san-diego" target="_blank"><img src="assets/images/Postmates.png" alt="Image" class="img-fluid"></a></div>
            <div ><a href="https://www.grubhub.com/restaurant/kebabish-7050-miramar-rd-san-diego/1278849" target="_blank"><img src="assets/images/Grubhub.png" alt="Image" class="img-fluid"></a></div>
            <div ><img src="assets/images/Fantuan.png" alt="Image" class="img-fluid"></div>
          </div>


        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 col-12 pl-lg-0 pl-md-0 pl-sm-6 pl-6 pr-lg-3 pr-md-3 pr-sm-3 pr-0 mt-md-0 mt-sm-0 mt-3 special-area">

      <div style="height: 100% !important;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pr-0">
          <div class="news-area" style="border: 1px solid;border-radius: 3px;border-color: hsla(0,0%,80%,.26)">
            <div class="text-center align-self-center px-3">
              <p class="m-0 mt-lg-0 mt-md-0 mt-sm-1 mt-2">{{news?.today_special}}</p>
              <p class=" font-weight-bold">{{news?.heading}}</p>
              <p class="news-desc">{{news?.description}}</p>
              <!--          <p>Ground beef in our secret combination of spices and gerbs, slowly cooked.</p>-->
            </div>
            <div>
              <img [src]="baseUrl+news?.image" class="img-fluid" alt="News">
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-md-2 mt-sm-2 mt-3 pr-0">
          <div class="text-center py-2 px-lg-5 px-md-3 px-sm-1 px-0 rated-area d-flex flex-column justify-content-center" style="border: 1px solid;border-radius: 3px;border-color: hsla(0,0%,80%,.26)">
            <p class="m-0"><span>
            <i class="fas fa-star text-warning"></i>
            <i class="fas fa-star text-warning"></i>
            <i class="fas fa-star text-warning"></i>
            <i class="fa fa-star text-warning"></i>
            <i class="fa fa-star text-warning"></i>
          </span></p>
            <p style="margin-bottom: 0.40rem !important;">Rated Best in San Diego</p>
            <p class="m-0 font-weight-bold line-height-1" *ngIf="ratingText === 1">"We finally found an authentic Pakistan place, food is so rich & hearty."</p>
            <p class="m-0 font-weight-bold line-height-1" *ngIf="ratingText === 2">"All dishes were unique from other Indian restaurants in San Diego."</p>
            <p class="m-0 font-weight-bold line-height-1" *ngIf="ratingText === 3">"Chicken biryani & reshmi boti are my favorites. Highly recommended."</p>
            <p class="m-0" *ngIf="ratingText === 1">Crystal Kamada - Google Local Guide</p>
            <p class="m-0" *ngIf="ratingText === 2">Isaac, Google Local Guide</p>
            <p class="m-0" *ngIf="ratingText === 3">Naba Rizvi, Google Local Guide</p>
            <p class="m-0"></p>
          </div>
        </div>
      </div>

    </div>
  </div>

<!--  <div class="row text-center mt-2">-->
<!--    <div class="col-md-4 col-sm-6 col-12">-->
<!--      <p style="font-size: 18px;">Online</p>-->
<!--      <p class="mt-4">click here to place online order</p>-->
<!--    </div>-->

<!--    <div class="col-md-4 col-sm-6 col-12">-->
<!--      <p style="font-size: 18px;">Delivery</p>-->
<!--      <div class="row m-0 delivery">-->
<!--        <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
<!--          <img src="../assets/images/uber-2752045-2284862.png" alt="">-->
<!--        </div>-->
<!--        <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
<!--          <img src="../assets/images/uber-2752045-2284862.png" alt="">-->
<!--        </div>-->
<!--        <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
<!--          <img src="../assets/images/uber-2752045-2284862.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-md-4 col-sm-6 col-12">-->
<!--      <p style="font-size: 18px;">By Phone</p>-->
<!--      <p class="mt-4"><a href="tel:+1-858-434-3234" style="text-decoration: none;color: #000000">+1-858-434-3234</a></p>-->
<!--    </div>-->
<!--  </div>-->

</section>

<!--Menu Section-->
<section id="menus" class="container-fluid menu-section mt-6 px-lg-5 px-md-4 px-sm-3 px-0">

  <div class="row text-center">
    <div class="col-12">
      <h3 class="mt-4">Menu</h3>
    </div>
  </div>



  <div class="d-flex mt-2 mx-3 pb-2 overflow-auto categories justify-content-center">

    <div class="d-flex overflow-auto sub-categories">
      <ng-container *ngFor="let data of categories">
      <div class="menu-categories mr-3" (click)="updateMenus(data)" [ngClass]="{ 'active-Category': data.id === activeCatId }">
        <div>
        <span>
        <a>
          <img *ngIf="data.id !== activeCatId" [src]="baseUrl+data?.image" alt="Image">
          <img *ngIf="data.id === activeCatId" [src]="baseUrl+data?.active_image" alt="Image">
          <!--          <img src="assets/images/burger.png" alt="Image">-->
        </a>
      </span>
          <span class="category-text" [ngClass]="{ 'font-weight-600': data.id === activeCatId }">{{data?.name}}</span>
        </div>
      </div>
    </ng-container>
    </div>

  </div>

  <div class="row m-0 mt-4 menus">

    <div class="col-12 mb-3 text-center">
      <p>{{activeCatText}}</p>
    </div>

<!--    style="border: 1px solid #eee;border-radius: 3px"-->
    <ng-container *ngIf="showMenu">
      <ng-container *ngFor="let data of menusCopy">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
<!--          data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="500"-->
          <div class="blog-box flex-center  ">
            <span class="blog-date line-height-1 font-size-15" style="padding: 10px 10px 5px 10px;!important;">{{data?.name}}</span>

            <ng-container class="display-flex">
              <ng-container *ngIf="data?.image">
                <div class="d-flex">
                  <div class="blog-box-des pb-0 pt-0">
                    <div class="">
                      <!--                                      <span class="blog-date line-height-1 font-size-15" style="margin: 10px 0 5px 0;!important;">{{data?.name}}</span>-->
                      <p class="blog-des line-height-17 font-size-14">{{data?.description}}</p>
                    </div>
                    <div>
                      <p class="mb-1">${{data?.price}}</p>
                    </div>
                  </div>

                  <div class="blog-box-img">
                    <a><img [src]="baseUrl+data?.image" class="img-fluid" alt="Menu Image"></a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!data?.image">
                <div class="blog-box-des w-100 only-desc pb-0">
                  <div>
                    <span class="blog-date line-height-1 font-size-15">{{data?.name}}</span>
                    <p class="blog-des line-height-17 font-size-14">{{data?.description}}</p>
                  </div>
                  <p class="mb-3">${{data?.price}}</p>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </ng-container>
    </ng-container>

  </div>

</section>


<!--Catering section-->
<section id="catering" class="catering-section mt-5 px-lg-5 px-md-4 px-sm-3 px-0">

  <div class="container-fluid">
    <div class="row text-center">
      <div class="col">
        <h4 class="mt-2">Catering</h4>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6 col-sm-12 col-12 catering-text" data-aos="fade-left" data-aos-duration="1000">
        <h5>Catering, Event Management, Hall Reservation</h5>
        <p>For a small birthday party, big wedding ceremony or a corporate event, we offer customized menu and event management services for every occasions to make them memorable. You can choose any number of buffet dishes or lunch box options, and we will deliver them all fresh to your location.</p>
        <p>Our dining area at Kebabish is also perfectly suited for events of up-to 50 guests.</p>
        <p>Contact us for catering menu and prices. For reservations, order placement or any other questions, feel free to call<a href="tel:858-597-0640" class="text-decoration-none color-black"> 858-597-0640</a> or fill up the form below.</p>
      </div>
      <div class="col-md-6 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="1000">
        <div>
          <img src="assets/images/Kebabish%20catering-new.png" alt="Image" style="width: 100%">
        </div>
      </div>
    </div>
  </div>

</section>


<!--Our meat section-->
<section id="meat" class="our-meat-section mt-5 pb-4 px-lg-5 px-md-4 px-sm-3 px-0">
  <div class="container-fluid">

    <div class="row text-center">
      <div class="col pt-3">
        <h4 class="mt-4">Our Meat</h4>
      </div>
    </div>

    <div class="row mx-0 mt-4">
      <div class="col-md-6 col-sm-12 col-12 plr-0">
        <div class="text-center" data-aos="fade-right" data-aos-duration="3000">
          <img src="assets/images/Kebabish%20-%20San%20Diego%20-%20Halal%20Meat.png" alt="Image" class="meat-image">
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-12 py-4 py-sm-0 py-md-0 meat-text plr-0">
          <div data-aos="fade-left" data-aos-duration="3000">
            <h5>100% Hand Slaughtered Zabihah Halal Meat</h5>
            <p>We source our meet only from the most reliable suppliers that provide 100% hand slaughtered zabiha halal meat. Whether it's chicken, goat or cow meat, it is always FDA approved, naturally grown, fresh and free from antibiotics.</p>
            <p>Our meat, ingredients and recipes make us the best Indian & Pakistani restaurant in San Diego. Whether you take-away or dine-in at our restaurant, you will always be served with dishes and BBQ prepared from fresh meat and ingredients.</p>
          </div>
        <div class="container px-lg-5 px-md-4 px-sm-3 px-1 meat-images">
          <div class="row">
            <div class="col align-self-center">
              <img src="assets/images/hr_logo1_small.png" class="img-fluid" alt="Image">
            </div>
            <div class="col align-self-center d-flex justify-content-center">
              <img src="assets/images/supplier_tahir_chicken_logo-min.jpg" class="img-fluid" alt="Image">
            </div>
            <div class="col align-self-center d-flex justify-content-center">
              <img src="assets/images/output-onlinepngtools%20(7).png" class="img-fluid" alt="Image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>


<!--Pictures section-->
<section id="picture" class="pictures-section mt-5 overflow-hidden">
  <div class="container">

    <div class="row text-center">
      <div class="col">
        <h4>Pictures</h4>
      </div>
    </div>

    <div class="row mt-4 picture-row">
      <ng-container *ngFor="let data of pictures">
<!--        pb-md-4 pb-sm-4 pb-2-->
        <div class="col-md-3 col-sm-6 col-6 px-md-3 px-sm-3 px-2 pictures" data-aos="fade-up" data-aos-duration="3000">
          <a (click)="imageFun(baseUrl+data?.image)">
            <img [src]="baseUrl+data?.image" alt="Picture" class="img-fluid" id="myImg">
          </a>
        </div>
      </ng-container>
<!--      <div class="col-md-3 col-sm-6 col-12 mb-3 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 mb-3 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 mb-3 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 col-12 px-2" data-aos="fade-up" data-aos-duration="3000"  >-->
<!--        <img src="../assets/images/istockphoto-114329354-612x612.jpg" alt="Picture" class="img-fluid">-->
<!--      </div>-->
    </div>
  </div>

</section>



<!--contact section-->
<section id="contact-us" class="contact-section mt-5 py-4 px-md-4 px-sm-3 px-0" style="overflow-y: hidden">
  <div class="container">

    <div class="row text-center mt-2">
      <div class="col-12">
        <h4>Contact Us</h4>
      </div>
    </div>

    <div class="row mt-4">

      <div class="col-12 col-sm-12 col-md-6 contact-text" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
        <div class="text-justify mb-2 line-height-1">
          <p>Kebabish offers you the best Pakistani & Indian cuisine in San Diego.</p>
          Join us to celebrate taste and quality.</div>

        <div class="timings">
          <div class="row">
            <ng-container *ngFor="let data of timings">
<!--              <ng-container *ngIf="data?.is_active === true">-->
              <div class="col-lg-3 col-md-4 col-sm-3 col-4">{{data?.days | titlecase}}</div>
<!--              <div class="col-lg-9 col-md-8 col-sm-9 col-6" *ngIf="data?.is_active === true">{{currentFormat(data?.opening_time) | date:'hh:mm a'}} - {{currentFormat(data?.closing_time) | date:'hh:mm a'}}</div>-->
              <div class="col-lg-9 col-md-8 col-sm-9 col-6" *ngIf="data?.is_active === true">{{data?.opening_time}} - {{data?.closing_time}}</div>
              <div class="col-lg-9 col-md-8 col-sm-9 col-6" *ngIf="data?.is_active === false">CLOSE</div>
<!--              </ng-container>-->
            </ng-container>
          </div>
        </div>
        <div class="mt-4 line-height-1">7050 Miramar Rd, San Diego, CA 92121 <br>Ph: <a href="tel:858-597-0640" class="text-decoration-none color-black">858-597-0640</a></div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 contact-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">


        <form>

          <div class="row form-section" style="margin: auto">

            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group mb-1">
                <div class="input-group">
                  <!--                <div class="input-group-prepend">-->
                  <!--                  <div class="input-group-text"><i class="fa fa-user text-info"></i></div>-->
                  <!--                </div>-->
                  <input type="text" class="form-control" name="name" placeholder="Full Name*" [ngModelOptions]="{standalone: true}" [(ngModel)]="name">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group mb-1">
                <div class="input-group">
                  <!--                <div class="input-group-prepend">-->
                  <!--                  <div class="input-group-text"><i class="fa fa-envelope text-info"></i></div>-->
                  <!--                </div>-->
                  <input type="email" class="form-control" name="email" placeholder="Email*" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group mb-1">
                <div class="input-group">
                  <!--                <div class="input-group-prepend">-->
                  <!--                  <div class="input-group-text"><i class="fa fa-mobile text-info"></i></div>-->
                  <!--                </div>-->
                  <input type="text" class="form-control" name="contact" placeholder="Contact" [ngModelOptions]="{standalone: true}" [(ngModel)]="phone" (keypress)="onlyNumeric($event)">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group mb-1">
                <div class="input-group">
                  <select class="form-control" [(ngModel)]="topic" [ngModelOptions]="{standalone: true}">
                    <option selected value="">Subject</option>
                    <option value="Catering">Catering</option>
                    <option value="Event at Kebabish">Event at Kebabish</option>
                    <option value="Other Information">Other Information</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-1">
                <div class="input-group">
                  <!--                <div class="input-group-prepend">-->
                  <!--                  <div class="input-group-text"><i class="fa fa-comment text-info"></i></div>-->
                  <!--                </div>-->
                  <textarea class="form-control" rows="3" placeholder="Message" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
              </div>
            </div>

            <div class="text-center w-100" style="text-align: center;">
              <button class="btn btn-primary submit-btn" (click)="onSubmit()" type="submit">SEND NOW</button>
            </div>
          </div>

        </form>


<!--        <form>-->

<!--            <div class="row">-->
<!--              <div class="col-12 col-sm-12 col-md-6">-->
<!--                <input type="text" placeholder="Name" name="name" [(ngModel)]="name">-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-12 col-md-6 left-padding-input">-->
<!--                <input type="text" placeholder="Email" name="email" [(ngModel)]="email">-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="col-12 col-sm-12 col-md-6">-->
<!--                <input type="text" placeholder="Phone" name="phone" [(ngModel)]="phone" (keyup)="onlyNumeric($event)">-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-12 col-md-6 left-padding-input">-->
<!--                &lt;!&ndash;              <input type="text" placeholder="Website">&ndash;&gt;-->
<!--                <select [(ngModel)]="topic" name="topic">-->
<!--                  <option value="">Topic</option>-->
<!--                  <option value="Free consulting session">Free consulting session</option>-->
<!--                  <option value="Web Development">Web Developement</option>-->
<!--                  <option value="Mobile App Development">Mobile App Developement</option>-->
<!--                  <option value="Quality Assurance">Quality Assurance</option>-->
<!--                  <option value="Team Augmentation">Team Augmentation</option>-->
<!--                  <option value="Others">Others</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="col">-->
<!--                <textarea rows="5" class="textarea" placeholder="Message" name="message" [(ngModel)]="message"></textarea>-->
<!--              </div>-->
<!--            </div>-->

<!--            <button (click)="onSubmit()" class="btn btn-primary" style="background-color: #ffffff;color: #000000">Send Message</button>-->
<!--        </form>-->

      </div>
    </div>

  </div>
</section>

<!--footer-->
<section class="footer-section text-white">
  <div class="container-fluid px-lg-5 px-md-4 px-sm-3 px-2">
    <div class="row">

      <div class="col-12 col-sm-12 col-md-6 my-auto">
        <p class="py-2 my-auto">© 2021 Copyrights <a href="https://irvinetek.com/" target="_blank" class="text-decoration-none text-white">Irvinetek</a> | Customized Web & Mobile App Software Development</p>
      </div>

      <div class="col-12 col-sm-12 col-md-6 my-auto footer-links footer-pages">
        <a>Corporate Social Responsibility </a>|
        <a>Terms & Conditions </a>|
        <a>Disclaimer</a>
      </div>

    </div>
  </div>
</section>



<!-- The Modal -->
<div id="myModal" class="modal">
  <span class="close" (click)="closeImageModal()">&times;</span>
  <img class="modal-content" id="img01">
<!--  <div id="caption"></div>-->
</div>



<style>
  /*body {font-family: Arial, Helvetica, sans-serif;}*/

  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    height: 100%;
  }

  /*#myImg:hover {opacity: 0.7;}*/

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(52,58,64,0.78); /* Black w/ opacity */
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }

  /* Caption of Modal Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }

  /* Add Animation */
  .modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
  }

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }
</style>
