<div class="container">

  <div class="card my-4">
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <h4>Timings</h4>
        </div>
      </div>

<!--      <div class="row mt-2">-->
<!--        <div class="col-6">-->
<!--          <label>Days</label>-->
<!--          <select class="form-control">-->
<!--            <option value="">Select</option>-->
<!--            <option value="Monday">Monday</option>-->
<!--            <option value="Tuesday">Tuesday</option>-->
<!--            <option value="Wednesday">Wednesday</option>-->
<!--            <option value="Thursday">Thursday</option>-->
<!--            <option value="Friday">Friday</option>-->
<!--            <option value="Saturday">Saturday</option>-->
<!--            <option value="Sunday">Sunday</option>-->
<!--          </select>-->
<!--        </div>-->

<!--        <div class="col-3">-->
<!--          <label>Opening Time</label>-->
<!--&lt;!&ndash;          <input type="text" class="form-control timepickers" id="openingTime">&ndash;&gt;-->
<!--        </div>-->

<!--        <div class="col-3">-->
<!--          <label>Closing Time</label>-->
<!--&lt;!&ndash;          <input type="text" class="form-control timepickers" id="closingTime">&ndash;&gt;-->
<!--        </div>-->

<!--      </div>-->


      <form [formGroup]="timingFrom">
        <div class="mt-4">
          <table class="table">
            <thead>
            <tr>
              <th>Day</th>
              <th>Opening Time</th>
              <th>Closing Time</th>
              <th class="text-center">Is Open</th>
            </tr>
            </thead>
            <tbody>
            <tr formArrayName="timings" *ngFor="let item of controlTimings.controls; let i = index;">
              <ng-container [formGroupName]="i">

                <td>
                  <input type="text" formControlName="days" class="form-control"  placeholder="Day"  [attr.disabled]="true">
                </td>
                <td>
                <input type="text" formControlName="opening_time" class="form-control timepickers" id="openingTime{{i}}">
                </td>
                <td>
                  <input type="text" formControlName="closing_time" class="form-control timepickers" id="closingTime{{i}}">
                </td>
                <td class="text-center">
                  <input type="checkbox" formControlName="is_active">
                </td>
                <!--            <td><span class="mr-3 pointer" (click)="editCat(data)"><i class="fas fa-edit"></i></span>-->
                <!--              <span class="pointer" (click)="deleteCat(data.id)"><i class="fas fa-trash-alt"></i></span></td>-->

              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </form>


      <div class="row text-right mt-4">
        <div class="col-12">
          <button type="button" class="btn btn-sm btn-primary" (click)="submitTiming()">Submit</button>
        </div>
      </div>

    </div>
  </div>

</div>
